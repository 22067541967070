import React from 'react'
import { urlWithSearchParamsHandler, srcSetProps, sanityImageUrl, clickTrack } from '../../../utils/format'
import { PopupButton } from '@typeform/embed-react'
import styles from './style.module.sass'

export default ({ healthCare }) => {
  if (!healthCare || healthCare.isHide) return null

  return (
    <div className={styles.healthCare}>
      <img
        {...srcSetProps(sanityImageUrl(healthCare.image))}
        className={styles.image}
        alt={healthCare.image?.caption}
      />
      <div className={styles.wrap}>
        <div className={styles.content}>
          <h2 className={styles.title}>{healthCare.title}</h2>
          <ul className={styles.list}>
            {healthCare.items &&
              healthCare.items.map((item, index) => (
                <li className={styles.item} key={item._key}>
                  <div className={styles.itemIndex}>{index + 1}</div>
                  <div className={styles.itemInfo}>
                    <p className={styles.itemTitle}>{item.title}</p>
                    <p className={styles.itemDescription}>{item.description}</p>
                  </div>
                </li>
              ))}
          </ul>
          {healthCare.buttonUrl?.includes('https://quiz/') ? (
            <PopupButton
              id={healthCare.buttonUrl.replace('https://quiz/', '')}
              as="a"
              onReady={() => clickTrack(healthCare.buttonText)}
              transitiveSearchParams={true}
              className={styles.link}
            >
              {healthCare.buttonText}
            </PopupButton>
          ) : (
            <a href={healthCare.buttonUrl} onClick={urlWithSearchParamsHandler} className={styles.link}>
              {healthCare.buttonText}
            </a>
          )}
        </div>
      </div>
    </div>
  )
}
